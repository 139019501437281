import { Box, Grid, IconButton, Switch, Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import MicIcon from "@material-ui/icons/Mic";
import SmsIcon from "@material-ui/icons/Sms";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import React from "react";
import settingsStyles from "../../../../../styles/settingsPageStyles";

const NotificationSetting = ({
  notificationSetting,
  setNotificationSetting,
}) => {
  const classes = settingsStyles();

  const handleChangeText = (event) => {
    setNotificationSetting({
      ...notificationSetting,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Grid container className={classes.section}>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 600 }} className={classes.labelSmall}>
          Notification Preferences
        </Typography>
      </Grid>
      <Grid item md={12} sm={12}>
        <Box className={classes.labelWithEdit} item>
          <IconButton style={{ "padding-left": 0 }} aria-label="Email">
            <EmailIcon />
          </IconButton>
          <Typography
            className={classes.labelSmall}
            style={{ margin: "auto 0" }}
          >
            Email
          </Typography>
          <Switch
            name="email"
            color="primary"
            checked={notificationSetting ? notificationSetting.email : false}
            onClick={handleChangeText}
            className={classes.switch}
          />
        </Box>
      </Grid>
      <Grid item md={12} sm={12}>
        <Box className={classes.labelWithEdit} item>
          <IconButton style={{ "padding-left": 0 }} aria-label="Sms">
            <SmsIcon />
          </IconButton>
          <Typography
            className={classes.labelSmall}
            style={{ margin: "auto 0" }}
          >
            SMS
          </Typography>
          <Switch
            name="sms"
            color="primary"
            checked={notificationSetting ? notificationSetting.sms : false}
            onClick={handleChangeText}
            className={classes.switch}
          />
        </Box>
      </Grid>
      <Grid item md={12} sm={12}>
        <Box className={classes.labelWithEdit} item>
          <IconButton style={{ "padding-left": 0 }} aria-label="Voice">
            <MicIcon />
          </IconButton>
          <Typography
            className={classes.labelSmall}
            style={{ margin: "auto 0" }}
          >
            Voice
          </Typography>
          <Switch
            name="voice"
            color="primary"
            checked={notificationSetting ? notificationSetting.voice : false}
            onClick={handleChangeText}
            className={classes.switch}
            disabled
          />
        </Box>
      </Grid>
      <Grid item md={12} sm={12}>
        <Box className={classes.labelWithEdit} item>
          <IconButton style={{ "padding-left": 0 }} aria-label="contactClinic">
            <ContactMailIcon />
          </IconButton>
          <Typography
            className={classes.labelSmall}
            style={{ margin: "auto 0" }}
          >
            Contact Your Clinic
          </Typography>
          <Switch
            name="contactClinic"
            color="primary"
            checked={
              notificationSetting ? notificationSetting.contactClinic : false
            }
            onClick={handleChangeText}
            className={classes.switch}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default NotificationSetting;
